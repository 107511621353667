<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

        <v-toolbar-title>{{
          teacher.firstname + " " + teacher.lastname
        }}</v-toolbar-title>
    </template>
    <v-container>
      <v-card
        width="100%"
        elevation="0"
        class="pa-2 text-center rounded-0"
      >

      <v-list-item-avatar size="100">
        <v-img :src="teacher.photo"></v-img>
      </v-list-item-avatar>

        <h2>{{ teacher.firstname + " " + teacher.lastname }}</h2>
        <p>{{ teacher.email }}</p>
      </v-card>

      <v-card class="rounded-0" elevation="0">
        <v-tabs
          v-model="tab"
          centered
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            Kontak
            <v-icon>mdi-phone</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Mapel
            <v-icon>mdi-book</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-card </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="teacher.nig"
                  ></v-list-item-title>
                  <v-list-item-subtitle>Nomor Induk</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-account </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="(teacher.firstname + teacher.lastname)"
                  ></v-list-item-title>
                  <v-list-item-subtitle>Nama</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-phone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="teacher.phone_no"
                  ></v-list-item-title>
                  <v-list-item-subtitle>No. Telp</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-email </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="teacher.email"></v-list-item-title>
                  <v-list-item-subtitle>Email</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="teacher.address"
                  ></v-list-item-title>
                  <v-list-item-subtitle>Alamat</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-list two-line>
              <v-list-item v-for="(subject, i) in subjects" :key="i" three-line>
                <v-list-item-content>
                  <v-list-item-title v-text="subject.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="'Kelas ' + subject.grade"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "TeacherInfo",
  components: { DefaultLayout },

  data: () => ({
    tab: null,
    teacher: {},
    subjects: []
  }),

  methods: {
    async getInfo() {
      this.$store.state.showPageOverlay = true;
      await this.$axios
        .get("teacher/info", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.$store.state.showPageOverlay = false;
          if (res.data.status == "success") {
            this.teacher = res.data.data.teacher;
            this.subjects = res.data.data.subjects;
          } else {
            this.teacher = [];
            this.subjects = [];
          }
        });
    },
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    } 
  },
  
  mounted() {
    this.getInfo();
    document.title = 'Info Siswa'
  },
};
</script>