<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>
    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">mdi-human-greeting-variant</v-icon>
        {{ teachers.length }} Tenaga Pengajar Profesional Kami
      </div>
      <v-divider></v-divider>
      <v-list denase height="100%" width="100%" class="mx-0">
        <v-skeleton-loader
          v-bind="attrs"
          v-if="loading"
          type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
        ></v-skeleton-loader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(teacher, i) in teachers"
            :key="i"
            @click="viewInfo(teacher.id)"
            two-line
          >
            <v-list-item-avatar>
              <v-img :src="teacher.photo"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="teacher.firstname + ' ' + teacher.lastname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="teacher.address"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-alert color="warning" v-if="loading == false && teachers.length == 0"
        >Oppss.. Belum ada data.</v-alert
      >
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "TeacherList",
  components: { DefaultLayout },

  data: () => ({
    teachers: [],
    selectedItem: null,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
    loading: null,
  }),
  methods: {
    getList() {
      this.loading = true;
      this.$axios.get("teacher/list").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.teachers = res.data.data;
        } else {
          this.teachers = [];
        }
        this.loading = false;
      });
    },
    viewInfo(id) {
      this.$router.push("/teacher-list/" + id + "/info");
    },
  },

  mounted() {
    this.getList();
    this.setPageTitle("Daftar Tenaga Pengajar", "mdi-human");
  },
};
</script>